import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_EVENT_CATEGORIES,
  BEGIN_EDIT_EVENT_CATEGORIES,
  BEGIN_EVENT_CATEGORIES_DOWNLOAD,
  RETRIEVE_EVENT_CATEGORIES_DELETE,
} from "../../constants/modules/eventCategoriesConstant";

import {
  addEventCategoryErrorAction,
  addEventCategoryOkAction,
  deleteEventCategoryErrorAction,
  deleteEventCategoryOkAction,
  downloadEventCategoriesErrorAction,
  downloadEventCategoriesOkAction,
  editEventCategoryErrorAction,
  editEventCategoryOkAction,
} from "../../actions/modules/eventCategoriesActions";

import {
  addEventCategoryDB,
  deleteEventCategoryDB,
  editEventCategoryDB,
  retrieveEventCategoriesData,
} from "../../../config/apiCalls/eventCategoriesAPICall.js";

// Retrieve products
// worker saga
export function* retrieveEventCategories() {
  try {
    const response = yield call(retrieveEventCategoriesData);
    
    if (response.status === 200) {
      yield put(downloadEventCategoriesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadEventCategoriesErrorAction());
  }
}

// Create new product
// worker saga
export function* addEventCategory(action) {
  const eventCategory = action.eventCategory;

  console.log("event%%%%%", eventCategory);

  try {
    const response = yield call(addEventCategoryDB, eventCategory);
    console.log("response222", response);

    if (response.status === 201) {
      yield delay(500);
      console.log("fire");
      yield put(addEventCategoryOkAction(eventCategory));
      toast.success("Event Category created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addEventCategoryErrorAction(true));
    toast.error("Event created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteEventCategory(action) {
  const id = action.payload;
  try {
    yield call(deleteEventCategoryDB, id);
    yield deleteEventCategoryOkAction();
    toast.success("Event Category deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteEventCategoryErrorAction();
  }
}

// Edit product
// worker saga
export function* editEventCategory(action) {
  const eventCategory = action.eventCategory;

  console.log("saga edit eventCategory", eventCategory);

  try {
    yield call(editEventCategoryDB, eventCategory);
    yield editEventCategoryOkAction(eventCategory);
    toast.success("Event Category Updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editEventCategoryErrorAction();
  }
}

export function* watchEventCategoriesAsync() {
  yield takeLatest(BEGIN_EVENT_CATEGORIES_DOWNLOAD, retrieveEventCategories);
  yield takeLatest(ADD_EVENT_CATEGORIES, addEventCategory);
  yield takeLatest(RETRIEVE_EVENT_CATEGORIES_DELETE, deleteEventCategory);
  yield takeLatest(BEGIN_EDIT_EVENT_CATEGORIES, editEventCategory);
}
