import {
  ADD_NOTICES,
  ADD_NOTICES_ERROR,
  ADD_NOTICES_OK,
  BEGIN_EDIT_NOTICES,
  BEGIN_NOTICES_DOWNLOAD,
  NOTICES_DELETED_ERROR,
  NOTICES_DELETED_OK,
  NOTICES_DOWNLOAD_ERROR,
  NOTICES_DOWNLOAD_OK,
  NOTICES_EDITED_ERROR,
  NOTICES_EDITED_OK,
  RETRIEVE_NOTICES_DELETE,
  RETRIEVE_NOTICES_EDIT,
} from "../../constants/modules/noticesConstant";

export const downloadNoticesAction = () => ({
  type: BEGIN_NOTICES_DOWNLOAD,
  payload: true,
});

export const downloadNoticesOkAction = (items) => ({
  type: NOTICES_DOWNLOAD_OK,
  payload: items,
});

export const downloadNoticesErrorAction = () => ({
  type: NOTICES_DOWNLOAD_ERROR,
  payload: true,
});

export const addNoticeAction = (item) => ({
  type: ADD_NOTICES,
  payload: true,
  notice: item,
});

export const addNoticeOkAction = () => ({
  type: ADD_NOTICES_OK,
  payload: true,
});

export const addNoticeErrorAction = (state) => ({
  type: ADD_NOTICES_ERROR,
  payload: state,
});

export const deleteNoticeAction = (id) => ({
  type: RETRIEVE_NOTICES_DELETE,
  payload: id,
});

export const deleteNoticeOkAction = () => ({
  type: NOTICES_DELETED_OK,
});

export const deleteNoticeErrorAction = () => ({
  type: NOTICES_DELETED_ERROR,
  payload: true,
});

export const retrieveNoticeAction = (item) => ({
  type: RETRIEVE_NOTICES_EDIT,
  payload: item,
});

export const editNoticeAction = (item) => ({
  type: BEGIN_EDIT_NOTICES,
  notice: item,
});

export const editNoticeOkAction = (item) => ({
  type: NOTICES_EDITED_OK,
  payload: item,
});

export const editNoticeErrorAction = () => ({
  type: NOTICES_EDITED_ERROR,
  payload: true,
});
