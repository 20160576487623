export const ADD_NOTICE_CATEGORIES = "ADD_NOTICE_CATEGORIES";
export const ADD_NOTICE_CATEGORIES_OK = "ADD_NOTICE_CATEGORIES_OK";
export const ADD_NOTICE_CATEGORIES_ERROR = "ADD_NOTICE_CATEGORIES_ERROR";

export const BEGIN_NOTICE_CATEGORIES_DOWNLOAD = "BEGIN_NOTICE_CATEGORIES_DOWNLOAD";
export const NOTICE_CATEGORIES_DOWNLOAD_OK = "NOTICE_CATEGORIES_DOWNLOAD_OK";
export const NOTICE_CATEGORIES_DOWNLOAD_ERROR = "NOTICE_CATEGORIES_DOWNLOAD_ERROR";

export const RETRIEVE_NOTICE_CATEGORIES_DELETE = "RETRIEVE_NOTICE_CATEGORIES_DELETE";
export const NOTICE_CATEGORIES_DELETED_OK = "NOTICE_CATEGORIES_DELETED_OK";
export const NOTICE_CATEGORIES_DELETED_ERROR = "NOTICE_CATEGORIES_DELETED_ERROR";

export const RETRIEVE_NOTICE_CATEGORIES_EDIT = "RETRIEVE_NOTICE_CATEGORIES_EDITED";
export const BEGIN_EDIT_NOTICE_CATEGORIES = "BEGIN_EDIT_NOTICE_CATEGORIES";
export const NOTICE_CATEGORIES_EDITED_OK = "NOTICE_CATEGORIES_EDITED_OK";
export const NOTICE_CATEGORIES_EDITED_ERROR = "NOTICE_CATEGORIES_EDITED_ERROR";

export const RETRIEVE_NOTICE_CATEGORIES_BULK_DELETE = "RETRIEVE_NOTICE_CATEGORIES_BULK_DELETE";
export const NOTICE_CATEGORIES_BULK_DELETED_OK = "NOTICE_CATEGORIES_BULK_DELETED_OK";
export const NOTICE_CATEGORIES_BULK_DELETED_ERROR = "NOTICE_CATEGORIES_BULK_DELETED_ERROR";