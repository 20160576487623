export const ADD_NOTICE_AREAS = "ADD_NOTICE_AREAS";
export const ADD_NOTICE_AREAS_OK = "ADD_NOTICE_AREAS_OK";
export const ADD_NOTICE_AREAS_ERROR = "ADD_NOTICE_AREAS_ERROR";

export const BEGIN_NOTICE_AREAS_DOWNLOAD = "BEGIN_NOTICE_AREAS_DOWNLOAD";
export const NOTICE_AREAS_DOWNLOAD_OK = "NOTICE_AREAS_DOWNLOAD_OK";
export const NOTICE_AREAS_DOWNLOAD_ERROR = "NOTICE_AREAS_DOWNLOAD_ERROR";

export const RETRIEVE_NOTICE_AREAS_DELETE = "RETRIEVE_NOTICE_AREAS_DELETE";
export const NOTICE_AREAS_DELETED_OK = "NOTICE_AREAS_DELETED_OK";
export const NOTICE_AREAS_DELETED_ERROR = "NOTICE_AREAS_DELETED_ERROR";

export const RETRIEVE_NOTICE_AREAS_EDIT = "RETRIEVE_NOTICE_AREAS_EDITED";
export const BEGIN_EDIT_NOTICE_AREAS = "BEGIN_EDIT_NOTICE_AREAS";
export const NOTICE_AREAS_EDITED_OK = "NOTICE_AREAS_EDITED_OK";
export const NOTICE_AREAS_EDITED_ERROR = "NOTICE_AREAS_EDITED_ERROR";

export const RETRIEVE_NOTICE_AREAS_BULK_DELETE = "RETRIEVE_NOTICE_AREAS_BULK_DELETE";
export const NOTICE_AREAS_BULK_DELETED_OK = "NOTICE_AREAS_BULK_DELETED_OK";
export const NOTICE_AREAS_BULK_DELETED_ERROR = "NOTICE_AREAS_BULK_DELETED_ERROR";