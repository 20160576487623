export const ADD_EVENT_CATEGORIES = "ADD_EVENT_CATEGORIES";
export const ADD_EVENT_CATEGORIES_OK = "ADD_EVENT_CATEGORIES_OK";
export const ADD_EVENT_CATEGORIES_ERROR = "ADD_EVENT_CATEGORIES_ERROR";

export const BEGIN_EVENT_CATEGORIES_DOWNLOAD = "BEGIN_EVENT_CATEGORIES_DOWNLOAD";
export const EVENT_CATEGORIES_DOWNLOAD_OK = "EVENT_CATEGORIES_DOWNLOAD_OK";
export const EVENT_CATEGORIES_DOWNLOAD_ERROR = "EVENT_CATEGORIES_DOWNLOAD_ERROR";

export const RETRIEVE_EVENT_CATEGORIES_DELETE = "RETRIEVE_EVENT_CATEGORIES_DELETE";
export const EVENT_CATEGORIES_DELETED_OK = "EVENT_CATEGORIES_DELETED_OK";
export const EVENT_CATEGORIES_DELETED_ERROR = "EVENT_CATEGORIES_DELETED_ERROR";

export const RETRIEVE_EVENT_CATEGORIES_EDIT = "RETRIEVE_EVENT_CATEGORIES_EDITED";
export const BEGIN_EDIT_EVENT_CATEGORIES = "BEGIN_EDIT_EVENT_CATEGORIES";
export const EVENT_CATEGORIES_EDITED_OK = "EVENT_CATEGORIES_EDITED_OK";
export const EVENT_CATEGORIES_EDITED_ERROR = "EVENT_CATEGORIES_EDITED_ERROR";

export const RETRIEVE_EVENT_CATEGORIES_BULK_DELETE = "RETRIEVE_EVENT_CATEGORIES_BULK_DELETE";
export const EVENT_CATEGORIES_BULK_DELETED_OK = "EVENT_CATEGORIES_BULK_DELETED_OK";
export const EVENT_CATEGORIES_BULK_DELETED_ERROR = "EVENT_CATEGORIES_BULK_DELETED_ERROR";