export const ADD_NOTICES = "ADD_NOTICES";
export const ADD_NOTICES_OK = "ADD_NOTICES_OK";
export const ADD_NOTICES_ERROR = "ADD_NOTICES_ERROR";

export const BEGIN_NOTICES_DOWNLOAD = "BEGIN_NOTICES_DOWNLOAD";
export const NOTICES_DOWNLOAD_OK = "NOTICES_DOWNLOAD_OK";
export const NOTICES_DOWNLOAD_ERROR = "NOTICES_DOWNLOAD_ERROR";

export const RETRIEVE_NOTICES_DELETE = "RETRIEVE_NOTICES_DELETE";
export const NOTICES_DELETED_OK = "NOTICES_DELETED_OK";
export const NOTICES_DELETED_ERROR = "NOTICES_DELETED_ERROR";

export const RETRIEVE_NOTICES_EDIT = "RETRIEVE_NOTICES_EDITED";
export const BEGIN_EDIT_NOTICES = "BEGIN_EDIT_NOTICES";
export const NOTICES_EDITED_OK = "NOTICES_EDITED_OK";
export const NOTICES_EDITED_ERROR = "NOTICES_EDITED_ERROR";
