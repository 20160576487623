import {
  ADD_EVENT_CATEGORIES,
  ADD_EVENT_CATEGORIES_ERROR,
  ADD_EVENT_CATEGORIES_OK,
  BEGIN_EDIT_EVENT_CATEGORIES,
  BEGIN_EVENT_CATEGORIES_DOWNLOAD,
  EVENT_CATEGORIES_DELETED_ERROR,
  EVENT_CATEGORIES_DELETED_OK,
  EVENT_CATEGORIES_DOWNLOAD_ERROR,
  EVENT_CATEGORIES_DOWNLOAD_OK,
  EVENT_CATEGORIES_EDITED_ERROR,
  EVENT_CATEGORIES_EDITED_OK,
  RETRIEVE_EVENT_CATEGORIES_DELETE,
  RETRIEVE_EVENT_CATEGORIES_EDIT,
} from "../../constants/modules/eventCategoriesConstant";

const initialState = {
  eventCategories: [],
  error: false,
  loading: false,
  deleteEventCategory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_EVENT_CATEGORIES_DOWNLOAD:
    case ADD_EVENT_CATEGORIES:
    case BEGIN_EDIT_EVENT_CATEGORIES:
      return {
        ...state,
        loading: action.payload,
        eventCategory: action.eventCategory,
      };

    case ADD_EVENT_CATEGORIES_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_EVENT_CATEGORIES_ERROR:
    case EVENT_CATEGORIES_DOWNLOAD_ERROR:
    case EVENT_CATEGORIES_DELETED_ERROR:
    case EVENT_CATEGORIES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EVENT_CATEGORIES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        eventCategories: action.payload,
      };

    case RETRIEVE_EVENT_CATEGORIES_DELETE:
      return {
        ...state,
        deleteEventCategory: action.payload,
      };

    case EVENT_CATEGORIES_DELETED_OK:
      return {
        ...state,
        eventCategories: state.eventCategories.data.filter((eventCategory) => eventCategory.id !== state.deleteEventCategory),
        deleteEventCategory: null,
      };

    case RETRIEVE_EVENT_CATEGORIES_EDIT:
      return {
        ...state,
        editEventCategory: action.payload,
      };

    case EVENT_CATEGORIES_EDITED_OK:
      return {
        ...state,
        editEventCategory: null,
        eventCategories: state.eventCategories.data.map((eventCategory) =>
          eventCategory.id === action.payload.id ? (eventCategory = action.payload) : eventCategory
        ),
      };

    default:
      return state;
  }
}
