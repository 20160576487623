import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_NOTICES,
  BEGIN_EDIT_NOTICES,
  BEGIN_NOTICES_DOWNLOAD,
  RETRIEVE_NOTICES_DELETE,
} from "../../constants/modules/noticesConstant";

import {
  addNoticeErrorAction,
  addNoticeOkAction,
  deleteNoticeErrorAction,
  deleteNoticeOkAction,
  downloadNoticesErrorAction,
  downloadNoticesOkAction,
  editNoticeErrorAction,
  editNoticeOkAction,
} from "../../actions/modules/noticesActions";

import {
  addNoticeDB,
  deleteNoticeDB,
  editNoticeDB,
  retrieveNoticesData,
} from "../../../config/apiCalls/noticesAPICall";

// Retrieve products
// worker saga
export function* retrieveNotices() {
  try {
    const response = yield call(retrieveNoticesData);
    
    if (response.status === 200) {
      yield put(downloadNoticesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadNoticesErrorAction());
  }
}

// Create new product
// worker saga
export function* addNotice(action) {
  const notice = action.notice;

  try {
    const response = yield call(addNoticeDB, notice);

    if (response.status === 201) {
      yield delay(500);
      console.log("fire");
      yield put(addNoticeOkAction(notice));
      toast.success("Notice created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addNoticeErrorAction(true));
    toast.error("Notice created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteNotice(action) {
  const id = action.payload;
  try {
    yield call(deleteNoticeDB, id);
    yield deleteNoticeOkAction();
    toast.success("Notice deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteNoticeErrorAction();
  }
}

// Edit product
// worker saga
export function* editNotice(action) {
  const notice = action.notice;

  console.log("saga edit Notice", notice);

  try {
    yield call(editNoticeDB, notice);
    yield editNoticeOkAction(notice);
    toast.success("Notice Updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editNoticeErrorAction();
  }
}

export function* watchNoticesAsync() {
  yield takeLatest(BEGIN_NOTICES_DOWNLOAD, retrieveNotices);
  yield takeLatest(ADD_NOTICES, addNotice);
  yield takeLatest(RETRIEVE_NOTICES_DELETE, deleteNotice);
  yield takeLatest(BEGIN_EDIT_NOTICES, editNotice);
}
