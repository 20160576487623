export const ADD_USERS = "ADD_USERS";
export const ADD_USERS_OK = "ADD_USERS_OK";
export const ADD_USERS_ERROR = "ADD_USERS_ERROR";

export const BEGIN_USERS_DOWNLOAD = "BEGIN_USERS_DOWNLOAD";
export const USERS_DOWNLOAD_OK = "USERS_DOWNLOAD_OK";
export const USERS_DOWNLOAD_ERROR = "USERS_DOWNLOAD_ERROR";

export const RETRIEVE_USERS_DELETE = "RETRIEVE_USERS_DELETE";
export const USERS_DELETED_OK = "USERS_DELETED_OK";
export const USERS_DELETED_ERROR = "USERS_DELETED_ERROR";

export const RETRIEVE_USERS_EDIT = "RETRIEVE_USERS_EDITED";
export const BEGIN_EDIT_USERS = "BEGIN_EDIT_USERS";
export const USERS_EDITED_OK = "USERS_EDITED_OK";
export const USERS_EDITED_ERROR = "USERS_EDITED_ERROR";

export const RETRIEVE_USERS_BULK_DELETE = "RETRIEVE_USERS_BULK_DELETE";
export const USERS_BULK_DELETED_OK = "USERS_BULK_DELETED_OK";
export const USERS_BULK_DELETED_ERROR = "USERS_BULK_DELETED_ERROR";