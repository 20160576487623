import axiosClient from "../axiosClient";

// Event Contents Api calls 

export async function bulkDeleteEventContentDB(ids) {
  return await axiosClient.post(`/event_contents/bulk_delete`, ids);
}

export async function retrieveEventContentsData() {
  return await axiosClient.get("/event_contents");
}

export async function addEventContentDB(value) {
  return await axiosClient.post("/event_contents", value);
}

export async function deleteEventContentDB(id) {
  return await axiosClient.delete(`/event_contents/${id}`);
}

export async function editEventContentDB(value) {
  return await axiosClient.put(`/event_contents/${value.id}`, value);
}
