import axiosClient from "../axiosClient";

// Notice Categories Api calls 

export async function bulkDeleteNoticeCategoryDB(ids) {
  return await axiosClient.post(`/notice_categories/bulk_delete`, ids);
}

// get all
export async function retrieveNoticeCategoriesData() {
  return await axiosClient.get("/notice_categories");
}

// add one
export async function addNoticeCategoryDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("notice_category", value.notice_category);
  bodyFormData.append("notice_rate", value.notice_rate);
  bodyFormData.append("per_day_rate", value.per_day_rate);
  bodyFormData.append("print_serial", value.print_serial);
  bodyFormData.append("published_status", value.published_status);
  bodyFormData.append("notice_icon", value.notice_icon);
 
  return await axiosClient.post("/notice_categories", bodyFormData);
}

// update one
export async function editNoticeCategoryDB(value) {
  var bodyFormData = new FormData();

  bodyFormData.append("notice_category", value.notice_category);
  bodyFormData.append("notice_rate", value.notice_rate);
  bodyFormData.append("per_day_rate", value.per_day_rate);
  bodyFormData.append("print_serial", value.print_serial);
  bodyFormData.append("published_status", value.published_status);
  bodyFormData.append("notice_icon", value.notice_icon);


  return await axiosClient.put(`/notice_categories/${value.id}`, bodyFormData);
}

// delete one
export async function deleteNoticeCategoryDB(id) {
  return await axiosClient.delete(`/notice_categories/${id}`);
}