import {
  ADD_USERS,
  ADD_USERS_ERROR,
  ADD_USERS_OK,
  BEGIN_EDIT_USERS,
  BEGIN_USERS_DOWNLOAD,
  RETRIEVE_USERS_DELETE,
  RETRIEVE_USERS_EDIT,
  USERS_DELETED_ERROR,
  USERS_DELETED_OK,
  USERS_DOWNLOAD_ERROR,
  USERS_DOWNLOAD_OK,
  USERS_EDITED_ERROR,
  USERS_EDITED_OK,
} from "../../constants/modules/usersConstant";

const initialState = {
  users: [],
  error: false,
  loading: false,
  deleteUser: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_USERS_DOWNLOAD:
    case ADD_USERS:
    case BEGIN_EDIT_USERS:
      return {
        ...state,
        loading: action.payload,
        user: action.user,
      };

    case ADD_USERS_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_USERS_ERROR:
    case USERS_DOWNLOAD_ERROR:
    case USERS_DELETED_ERROR:
    case USERS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USERS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.payload,
      };

    case RETRIEVE_USERS_DELETE:
      return {
        ...state,
        deleteUser: action.payload,
      };

    case USERS_DELETED_OK:
      return {
        ...state,
        users: state.users.data.filter((user) => user.id !== state.deleteUser),
        deleteUser: null,
      };

    case RETRIEVE_USERS_EDIT:
      return {
        ...state,
        editUser: action.payload,
      };

    case USERS_EDITED_OK:
      return {
        ...state,
        editUser: null,
        users: state.users.data.map((user) =>
          user.id === action.payload.id ? (user = action.payload) : user
        ),
      };

    default:
      return state;
  }
}
