import {
  ADD_NOTICE_AREAS,
  ADD_NOTICE_AREAS_ERROR,
  ADD_NOTICE_AREAS_OK,
  BEGIN_EDIT_NOTICE_AREAS,
  BEGIN_NOTICE_AREAS_DOWNLOAD,
  NOTICE_AREAS_DELETED_ERROR,
  NOTICE_AREAS_DELETED_OK,
  NOTICE_AREAS_DOWNLOAD_ERROR,
  NOTICE_AREAS_DOWNLOAD_OK,
  NOTICE_AREAS_EDITED_ERROR,
  NOTICE_AREAS_EDITED_OK,
  RETRIEVE_NOTICE_AREAS_DELETE,
  RETRIEVE_NOTICE_AREAS_EDIT,
} from "../../constants/modules/noticeAreasConstant";
  
  export const downloadNoticeAreasAction = () => ({
    type: BEGIN_NOTICE_AREAS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadNoticeAreasOkAction = (items) => ({
    type: NOTICE_AREAS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadNoticeAreasErrorAction = () => ({
    type: NOTICE_AREAS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addNoticeAreaAction = (item) => ({
    type: ADD_NOTICE_AREAS,
    payload: true,
    noticeArea: item,
  });
  
  export const addNoticeAreaOkAction = () => ({
    type: ADD_NOTICE_AREAS_OK,
    payload: true,
  });
  
  export const addNoticeAreaErrorAction = (state) => ({
    type: ADD_NOTICE_AREAS_ERROR,
    payload: state,
  });
  
  export const deleteNoticeAreaAction = (id) => ({
    type: RETRIEVE_NOTICE_AREAS_DELETE,
    payload: id,
  });
  
  export const deleteNoticeAreaOkAction = () => ({
    type: NOTICE_AREAS_DELETED_OK,
  });
  
  export const deleteNoticeAreaErrorAction = () => ({
    type: NOTICE_AREAS_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveNoticeAreaAction = (item) => ({
    type: RETRIEVE_NOTICE_AREAS_EDIT,
    payload: item,
  });
  
  export const editNoticeAreaAction = (item) => ({
    type: BEGIN_EDIT_NOTICE_AREAS,
    noticeArea: item,
  });
  
  export const editNoticeAreaOkAction = (item) => ({
    type: NOTICE_AREAS_EDITED_OK,
    payload: item,
  });
  
  export const editNoticeAreaErrorAction = () => ({
    type: NOTICE_AREAS_EDITED_ERROR,
    payload: true,
  });
  