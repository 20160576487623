import {
  ADD_NOTICE_AREAS,
  ADD_NOTICE_AREAS_ERROR,
  ADD_NOTICE_AREAS_OK,
  BEGIN_EDIT_NOTICE_AREAS,
  BEGIN_NOTICE_AREAS_DOWNLOAD,
  NOTICE_AREAS_DELETED_ERROR,
  NOTICE_AREAS_DELETED_OK,
  NOTICE_AREAS_DOWNLOAD_ERROR,
  NOTICE_AREAS_DOWNLOAD_OK,
  NOTICE_AREAS_EDITED_ERROR,
  NOTICE_AREAS_EDITED_OK,
  RETRIEVE_NOTICE_AREAS_DELETE,
  RETRIEVE_NOTICE_AREAS_EDIT,
} from "../../constants/modules/noticeAreasConstant";

const initialState = {
  noticeAreas: [],
  error: false,
  loading: false,
  deleteNoticeArea: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_NOTICE_AREAS_DOWNLOAD:
    case ADD_NOTICE_AREAS:
    case BEGIN_EDIT_NOTICE_AREAS:
      return {
        ...state,
        loading: action.payload,
        noticeArea: action.noticeArea,
      };

    case ADD_NOTICE_AREAS_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_NOTICE_AREAS_ERROR:
    case NOTICE_AREAS_DOWNLOAD_ERROR:
    case NOTICE_AREAS_DELETED_ERROR:
    case NOTICE_AREAS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTICE_AREAS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        noticeAreas: action.payload,
      };

    case RETRIEVE_NOTICE_AREAS_DELETE:
      return {
        ...state,
        deleteNoticeArea: action.payload,
      };

    case NOTICE_AREAS_DELETED_OK:
      return {
        ...state,
        noticeAreas: state.noticeAreas.data.filter((noticeArea) => noticeArea.id !== state.deleteNoticeArea),
        deleteNoticeArea: null,
      };

    case RETRIEVE_NOTICE_AREAS_EDIT:
      return {
        ...state,
        editNoticeArea: action.payload,
      };

    case NOTICE_AREAS_EDITED_OK:
      return {
        ...state,
        editNoticeArea: null,
        noticeAreas: state.noticeAreas.data.map((noticeArea) =>
          noticeArea.id === action.payload.id ? (noticeArea = action.payload) : noticeArea
        ),
      };

    default:
      return state;
  }
}
