import { combineReducers } from "redux";
import MenuReducers from "./menuReducers";
import uiReducer from "./uiReducers";
//admin

//settings
import LoginReducers from "./auth/authReducers";
import permissionsReducers from "./auth/permissionsReducers";
import eventCategoriesReducers from "./modules/eventCategoriesReducers";
import eventContentsReducers from "./modules/eventContentsReducers";
import eventsReducers from "./modules/eventsReducers";
import noticeAreasReducers from "./modules/noticeAreasReducers";
import noticeCategoriesReducers from "./modules/noticeCategoriesReducers";
import noticesReducers from "./modules/noticesReducers";
import usersReducers from "./modules/usersReducers";

const rootReducer = combineReducers({
  ui: uiReducer,
  menuList: MenuReducers,
  eventList: eventsReducers,
  eventCategoryList: eventCategoriesReducers,
  eventContentList: eventContentsReducers,
  noticeList: noticesReducers,
  noticeAreaList: noticeAreasReducers,
  noticeCategoryList: noticeCategoriesReducers,
  userList: usersReducers,
  loginInfoList: LoginReducers,
  permissionList: permissionsReducers,
 
});

export default rootReducer;
