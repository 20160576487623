import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_USERS,
  BEGIN_EDIT_USERS,
  BEGIN_USERS_DOWNLOAD,
  RETRIEVE_USERS_DELETE,
} from "../../constants/modules/usersConstant";

import {
  addUserErrorAction,
  addUserOkAction,
  deleteUserErrorAction,
  deleteUserOkAction,
  downloadUsersErrorAction,
  downloadUsersOkAction,
  editUserErrorAction,
  editUserOkAction,
} from "../../actions/modules/usersActions";

import {
  addUserDB,
  deleteUserDB,
  editUserDB,
  retrieveUsersData,
} from "../../../config/apiCalls/usersAPICall.js";

// Retrieve products
// worker saga
export function* retrieveUsers() {
  try {
    const response = yield call(retrieveUsersData);
    
    if (response.status === 200) {
      yield put(downloadUsersOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadUsersErrorAction());
  }
}

// Create new product
// worker saga
export function* addUser(action) {
  const user = action.user;

  console.log("user%%%%%", user);

  try {
    const response = yield call(addUserDB, user);
    console.log("response222", response);

    if (response.status === 201) {
      yield delay(500);
      console.log("fire");
      yield put(addUserOkAction(user));
      toast.success("User created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addUserErrorAction(true));
    toast.error("User created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteUser(action) {
  const id = action.payload;
  try {
    yield call(deleteUserDB, id);
    yield deleteUserOkAction();
    toast.success("User deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteUserErrorAction();
  }
}

// Edit product
// worker saga
export function* editUser(action) {
  const user = action.user;

  console.log("saga edit user", user);

  try {
    yield call(editUserDB, user);
    yield editUserOkAction(user);
    toast.success("User Updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editUserErrorAction();
  }
}

export function* watchUsersAsync() {
  yield takeLatest(BEGIN_USERS_DOWNLOAD, retrieveUsers);
  yield takeLatest(ADD_USERS, addUser);
  yield takeLatest(RETRIEVE_USERS_DELETE, deleteUser);
  yield takeLatest(BEGIN_EDIT_USERS, editUser);
}
