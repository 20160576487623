import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { lazy, Suspense, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Header, Sidebar, Workspace } from "../components";
import axiosClient from "../config/axiosClient";
import useMountEffect from "../mountEffect";
import { MobileBreakpoint } from "../styleVariables";

//Components

const Events = lazy(() => import("../pages/modules/Events/index"));
const NoticeArea = lazy(() => import("../pages/modules/NoticeArea/index"));
const NoticeCategory = lazy(() => import("../pages/modules/NoticeCategory/index"));
const EventCategory = lazy(() => import("../pages/modules/EventsCategory/index"));
const EventContent = lazy(() => import("../pages/modules/EventContent/index"));
const Notices = lazy(() => import("../pages/modules/Notices/index"));
const AdminBoard = lazy(() => import("../pages/modules/Users/index"));


const useStyles = makeStyles((theme) => ({
  panel: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      minHeight: "calc(100vh - 64px)",
      paddingTop: "64px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      minHeight: "calc(100vh - 56px)",
      paddingTop: "56px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 64px)",
    },
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 3,
  },
}));

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(true);
  const [userDetails, setUserDetails] = useState("");

  const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);

  const resizeDispatch = () => {
    if (typeof Event === "function") {
      window.dispatchEvent(new Event("resize"));
    } else {
      const evt = window.document.createEvent("UIEvents");
      evt.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(evt);
    }
  };

  const handleDrawerToggle = () => {
    setOpened(!opened);
    resizeDispatch();
  };

 

  const getRoutes = (
    <Switch>
      {/* notice routes start*/}
      <Route path="/app/events">
        <Events userDetails={userDetails} />
      </Route>
      <Route path="/app/event_category">
        <EventCategory userDetails={userDetails} />
      </Route>
      <Route path="/app/event_content">
        <EventContent userDetails={userDetails} />
      </Route>
      <Route path="/app/notices">
        <Notices userDetails={userDetails} />
      </Route>
      <Route path="/app/notice_areas">
        <NoticeArea userDetails={userDetails} />
      </Route>
      <Route path="/app/notice_category">
        <NoticeCategory userDetails={userDetails} />
      </Route>
      <Route path="/app/users">
        <AdminBoard userDetails={userDetails} />
      </Route>
      
      {/* notice routes end */}
     

      {/* <Redirect to="/404" /> */}
      <Redirect exact from="/app" to="/app/events" />
    </Switch>
  );

  useMountEffect(() => {
    if (mediaMatcher.matches) setOpened(false);
    mediaMatcher.addListener((match) => {
      setTimeout(() => {
        if (match.matches) setOpened(false);
        else setOpened(true);
      }, 300);
    });

    const unlisten = history.listen(() => {
      if (mediaMatcher.matches) setOpened(false);
      document.querySelector("#root > div > main").scrollTop = 0;
    });

    return () => {
      unlisten();
      mediaMatcher.removeListener((match) => {
        setTimeout(() => {
          if (match.matches) setOpened(false);
          else setOpened(true);
        }, 300);
      });
    };
  });



  React.useEffect(() => {
    const getToken = localStorage.getItem("dm-userInfo")
      ? JSON.parse(localStorage.getItem("dm-userInfo"))
      : null;

    const token = "Bearer " + getToken?.token;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    };

    axiosClient.get("/auth/login/user", config).then((response) => {
      setUserDetails(response?.data?.data);
    });

    // fetch(`http://localhost:5000/api/auth/login/user`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: token,
    //   },
    // }).then((response) => {
    //   response.json().then((result) => {
    //     setUserDetails(result?.data);
    //   });
    // });
  }, []);

  
  const token = localStorage.getItem("dm-userInfo");

  if (!token) {
    return <Redirect to="/signin" />;
  } else {
    return (
      <>
        <Header
          logoAltText="Primer Admin Template"
          logo={`${process.env.PUBLIC_URL}/static/images/notice.png`}
          toggleDrawer={handleDrawerToggle}
         
          userDetails={userDetails}
        />
        <div
          className={classNames(classes.panel, "theme-dark")}
         
        >
          <Sidebar
            opened={opened}
            toggleDrawer={handleDrawerToggle}
            userDetails={userDetails}
          />
          <Workspace opened={opened} toggleDrawer={handleDrawerToggle}>
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "calc(100vh-70px)",
                  }}
                >
                  <CircularProgress color="secondary" />
                </div>
              }
            >
              {getRoutes}
            </Suspense>
          </Workspace>
        </div>
        <ToastContainer />
      </>
    );
  }
};

export default Dashboard;
