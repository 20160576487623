import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_EVENT_CONTENTS,
  BEGIN_EDIT_EVENT_CONTENTS,
  BEGIN_EVENT_CONTENTS_DOWNLOAD,
  RETRIEVE_EVENT_CONTENTS_DELETE,
} from "../../constants/modules/eventContentsConstant";

import {
  addEventContentErrorAction,
  addEventContentOkAction,
  deleteEventContentErrorAction,
  deleteEventContentOkAction,
  downloadEventContentsErrorAction,
  downloadEventContentsOkAction,
  editEventContentErrorAction,
  editEventContentOkAction,
} from "../../actions/modules/eventContentsActions";

import {
  addEventContentDB,
  deleteEventContentDB,
  editEventContentDB,
  retrieveEventContentsData,
} from "../../../config/apiCalls/eventContentsAPICall.js";

// Retrieve products
// worker saga
export function* retrieveEventContents() {
  try {
    const response = yield call(retrieveEventContentsData);
    
    if (response.status === 200) {
      yield put(downloadEventContentsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadEventContentsErrorAction());
  }
}

// Create new product
// worker saga
export function* addEventContent(action) {
  const eventContent = action.eventContent;

  console.log("event%%%%%", eventContent);

  try {
    const response = yield call(addEventContentDB, eventContent);
    console.log("response222", response);

    if (response.status === 201) {
      yield delay(500);
      console.log("fire");
      yield put(addEventContentOkAction(eventContent));
      toast.success("Event Content created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addEventContentErrorAction(true));
    toast.error("Event Content Created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteEventContent(action) {
  const id = action.payload;
  try {
    yield call(deleteEventContentDB, id);
    yield deleteEventContentOkAction();
    toast.success("Event Content deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteEventContentErrorAction();
  }
}

// Edit product
// worker saga
export function* editEventContent(action) {
  const eventContent  = action.eventContent;
  try {
    console.log("editEventContentDB", "data",  eventContent);

    yield call(editEventContentDB, eventContent);
    yield editEventContentOkAction(eventContent);

    console.log("editEventContentDB", "data1",  eventContent);
    toast.success("Event Content Updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editEventContentErrorAction();
  }
}

export function* watchEventContentsAsync() {
  yield takeLatest(BEGIN_EVENT_CONTENTS_DOWNLOAD, retrieveEventContents);
  yield takeLatest(ADD_EVENT_CONTENTS, addEventContent);
  yield takeLatest(RETRIEVE_EVENT_CONTENTS_DELETE, deleteEventContent);
  yield takeLatest(BEGIN_EDIT_EVENT_CONTENTS, editEventContent);
}
