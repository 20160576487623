import axiosClient from "../axiosClient";

// Notices Api calls 

export async function bulkDeleteNoticeDB(ids) {
  return await axiosClient.post(`/notices/bulk_delete`, ids);
}

export async function retrieveNoticesData() {
  return await axiosClient.get("/notices");
}

export async function addNoticeDB(value) {
  return await axiosClient.post("/notices", value);
}

export async function deleteNoticeDB(id) {
  return await axiosClient.delete(`/notices/${id}`);
}

export async function editNoticeDB(value) {
  console.log("editvalues", value)
  
    var bodyFormData = new FormData();

    

  bodyFormData.append("bill_amt", value.bill_amt);
  bodyFormData.append("end_date", value.end_date);
  bodyFormData.append("featured", value.featured);
  bodyFormData.append("notice", value.notice);
  bodyFormData.append("notice_area_id", value.notice_area_id);
  bodyFormData.append("notice_category_id", value.notice_category_id);
  bodyFormData.append("notice_image_1", value.notice_image_1);
  bodyFormData.append("notice_image_2", value.notice_image_2);
  bodyFormData.append("notice_image_3", value.notice_image_3);
  bodyFormData.append("paid_amt", value.paid_amt);
  bodyFormData.append("publish_date", value.publish_date);
  bodyFormData.append("published_status", value.published_status);
  bodyFormData.append("user_id", value.user_id);

  
  console.log("editvalues", bodyFormData);

  return await axiosClient.put(`/notices/${value.id}`, bodyFormData);
}
