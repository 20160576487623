import {
  ADD_EVENTS,
  ADD_EVENTS_ERROR,
  ADD_EVENTS_OK,
  BEGIN_EDIT_EVENTS,
  BEGIN_EVENTS_DOWNLOAD,
  EVENTS_DELETED_ERROR,
  EVENTS_DELETED_OK,
  EVENTS_DOWNLOAD_ERROR,
  EVENTS_DOWNLOAD_OK,
  EVENTS_EDITED_ERROR,
  EVENTS_EDITED_OK,
  RETRIEVE_EVENTS_DELETE,
  RETRIEVE_EVENTS_EDIT,
} from "../../constants/modules/eventsConstant";

const initialState = {
  events: [],
  error: false,
  loading: false,
  deleteEvent: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_EVENTS_DOWNLOAD:
    case ADD_EVENTS:
    case BEGIN_EDIT_EVENTS:
      return {
        ...state,
        loading: action.payload,
        event: action.event,
      };

    case ADD_EVENTS_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_EVENTS_ERROR:
    case EVENTS_DOWNLOAD_ERROR:
    case EVENTS_DELETED_ERROR:
    case EVENTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EVENTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        events: action.payload,
      };

    case RETRIEVE_EVENTS_DELETE:
      return {
        ...state,
        deleteEvent: action.payload,
      };

    case EVENTS_DELETED_OK:
      return {
        ...state,
        events: state.events.data.filter((event) => event.id !== state.deleteEvent),
        deleteEvent: null,
      };

    case RETRIEVE_EVENTS_EDIT:
      return {
        ...state,
        editEvent: action.payload,
      };

    case EVENTS_EDITED_OK:
      return {
        ...state,
        editEvent: null,
        events: state.events.data.map((event) =>
          event.id === action.payload.id ? (event = action.payload) : event
        ),
      };

    default:
      return state;
  }
}
