
export const data = [
  {
    id: 36,
    app_module_id: 12,
    menu_caption: "Events",
    menu_link: "/events",
    menu_icon: "event_note",
    parent_menu: 0,
    menu_status: "A",
    created_by: "Prottoy",
    created_date: "2021-05-02 13:05:46",
    modified_by: "Shishir",
    modified_date: "2021-05-08 00:45:30",
  },
  {
    id: 37,
    app_module_id: 12,
    menu_caption: "Event Category",
    menu_link: "/event_category",
    menu_icon: "developer_board",
    parent_menu: 0,
    menu_status: "A",
    created_by: "Prottoy",
    created_date: "2021-05-02 13:06:56",
    modified_by: null,
    modified_date: "2021-05-02 13:06:56",
  },
  // published_with_changes
  // notifications
  {
    id: 38,
    app_module_id: 12,
    menu_caption: "Event Content",
    menu_link: "/event_content",
    menu_icon: "event",
    parent_menu: 0,
    menu_status: "A",
    created_by: "Prottoy",
    created_date: "2021-05-02 13:07:41",
    modified_by: "Shishir",
    modified_date: "2021-05-06 22:10:07",
  },
  {
    id: 39,
    app_module_id: 12,
    menu_caption: "Notices",
    menu_link: "/notices",
    menu_icon: "notifications",
    parent_menu: 0,
    menu_status: "A",
    created_by: "Prottoy",
    created_date: "2021-05-02 13:08:02",
    modified_by: "Shishir",
    modified_date: "2021-05-06 22:12:31",
  },
  {
    id: 40,
    app_module_id: 12,
    menu_caption: "Notice Areas",
    menu_link: "/notice_areas",
    menu_icon: "notification_add",
    parent_menu: 0,
    menu_status: "A",
    created_by: "Prottoy",
    created_date: "2021-05-02 13:08:02",
    modified_by: "Shishir",
    modified_date: "2021-05-06 22:12:31",
  },
  {
    id: 41,
    app_module_id: 12,
    menu_caption: "Notice Category",
    menu_link: "/notice_category",
    menu_icon: "edit_notifications",
    parent_menu: 0,
    menu_status: "A",
    created_by: "Prottoy",
    created_date: "2021-05-02 13:08:02",
    modified_by: "Shishir",
    modified_date: "2021-05-06 22:12:31",
  },
  {
    id: 42,
    app_module_id: 12,
    menu_caption: "Users",
    menu_link: "/users",
    menu_icon: "admin_panel_settings",
    parent_menu: 0,
    menu_status: "A",
    created_by: "Prottoy",
    created_date: "2021-05-02 13:08:02",
    modified_by: "Shishir",
    modified_date: "2021-05-06 22:12:31",
  },
];


export const data2 = [
  
  {
    id: 26,
    app_module_id: null,
    menu_caption: "Dashboard",
    menu_link: "/",
    menu_icon: null,
    parent_menu: 0,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:42:54.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:42:54.000Z",
  },
  {
    id: 27,
    app_module_id: null,
    menu_caption: "Settings",
    menu_link: "/",
    menu_icon: null,
    parent_menu: 0,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:42:54.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:42:54.000Z",
  },
  {
    id: 28,
    app_module_id: null,
    menu_caption: "Regular Works",
    menu_link: "/",
    menu_icon: null,
    parent_menu: 0,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:42:54.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:42:54.000Z",
  },
  {
    id: 29,
    app_module_id: null,
    menu_caption: "Documents",
    menu_link: "/",
    menu_icon: null,
    parent_menu: 0,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:42:54.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:42:54.000Z",
  },
  {
    id: 30,
    app_module_id: null,
    menu_caption: "Admin Panel",
    menu_link: "/",
    menu_icon: null,
    parent_menu: 0,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:42:54.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:42:54.000Z",
  },
  {
    id: 31,
    app_module_id: null,
    menu_caption: "Apps Users",
    menu_link: "/app_users",
    menu_icon: null,
    parent_menu: 6,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:44:25.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:44:25.000Z",
  },
  {
    id: 32,
    app_module_id: null,
    menu_caption: "User Roles",
    menu_link: "/user_roles",
    menu_icon: null,
    parent_menu: 6,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:44:25.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:44:25.000Z",
  },
  {
    id: 33,
    app_module_id: null,
    menu_caption: "User Access Control",
    menu_link: "/user_access_controll",
    menu_icon: null,
    parent_menu: 6,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:44:25.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:44:25.000Z",
  },
  {
    id: 34,
    app_module_id: null,
    menu_caption: "Overseas Offices",
    menu_link: "/overseas_offices",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:45:20.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:45:20.000Z",
  },
  {
    id: 35,
    app_module_id: null,
    menu_caption: "Suppliers",
    menu_link: "/suppliers",
    menu_icon: null,
    parent_menu: 27,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:46:15.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:46:15.000Z",
  },
  {
    id: 36,
    app_module_id: null,
    menu_caption: "Banks",
    menu_link: "/banks",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:46:49.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:46:49.000Z",
  },
  {
    id: 37,
    app_module_id: null,
    menu_caption: "Bank Accounts",
    menu_link: "/bank_accounts",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:47:18.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:47:18.000Z",
  },
  {
    id: 38,
    app_module_id: null,
    menu_caption: "Buyers",
    menu_link: "/buyers",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:47:43.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:47:43.000Z",
  },
  {
    id: 39,
    app_module_id: null,
    menu_caption: "Buyer Terms",
    menu_link: "/buyer_terms",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:49:14.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:49:14.000Z",
  },
  {
    id: 40,
    app_module_id: null,
    menu_caption: "Buyer Brands",
    menu_link: "/buyer_brands",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:49:54.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:49:54.000Z",
  },
  {
    id: 41,
    app_module_id: null,
    menu_caption: "Buyer Departments",
    menu_link: "/buyer_dept",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:50:29.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:50:29.000Z",
  },
  {
    id: 42,
    app_module_id: null,
    menu_caption: "Size Sets",
    menu_link: "/size_sets",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:50:58.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:50:58.000Z",
  },
  {
    id: 43,
    app_module_id: null,
    menu_caption: "Colors",
    menu_link: "/colors",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:51:25.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:51:25.000Z",
  },
  {
    id: 44,
    app_module_id: null,
    menu_caption: "Ex-factories",
    menu_link: "/ex_factories",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:51:58.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:51:58.000Z",
  },
  {
    id: 45,
    app_module_id: null,
    menu_caption: "Product Types",
    menu_link: "/product_types",
    menu_icon: null,
    parent_menu: 3,
    menu_status: "A",
    created_by: null,
    created_date: "2022-10-09T09:52:27.000Z",
    modified_by: null,
    modified_date: "2022-10-09T09:52:27.000Z",
  },
];
