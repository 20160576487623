import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";

import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Tooltip from "@material-ui/core/Tooltip";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import AppMenuItemComponent from "./SubMenuItems";

import ModuleChildrenItem from "./SubMenuItem";

const ModuleItems = ({ sidebarOpen, toggleMenu, activeRoute, ...item }) => {
  const {
    menu_caption,
    menu_link,
    parent_menu,
    menu_icon,
    id,
    children = [],
  } = item;
  const classes = useStyles();
  const isExpandable = children && children.length > 0;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  function handleClick() {
    setOpen(!open);
    setValue(menu_caption);
  }

  console.log("sidebar", menu_icon, parent_menu, sidebarOpen);

  const [activeRoute2, setActiveRoute2] = React.useState(undefined);
  const toggleMenu2 = (index) =>
    setActiveRoute2(activeRoute2 === index ? undefined : index);

  const MenuItemRoot = (
    <AppMenuItemComponent
      className={
        activeRoute === id ? classes.menuItemSelected : classes.menuItem
      }
      menu_link={menu_link}
      parent_menu={parent_menu}
      onClick={() => toggleMenu(id)}
    >
      {/* Display an icon if any */}
      {menu_icon && !sidebarOpen ?
       (
        <Tooltip
          title={menu_caption}
          placement="right"
          interactive
          disableHoverListener={sidebarOpen}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon>{menu_icon}</Icon>
          </ListItemIcon>
        </Tooltip>
      ) 

      : (
        <ListItemIcon className={classes.menuItemIcon}>
            <Icon>{menu_icon}</Icon>
          </ListItemIcon>
      )}
      <ListItemText
        classes={{
          primary:
            activeRoute === id
              ? classes.listItemTextOpen
              : activeRoute !== id
              ? classes.ListItemText
              : classes.listItemTextClose,
        }}
        primary={menu_caption}
        inset={!menu_icon}
      />
      {/* Display the expand menu if the item has children */}
      {isExpandable && activeRoute !== id && (
        <IconExpandMore style={{ fontSize: 18 }} />
      )}
      {isExpandable && activeRoute === id && (
        <IconExpandLess style={{ fontSize: 18 }} />
      )}
    </AppMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      in={activeRoute === id ? true : false}
      timeout="auto"
      unmountOnExit
      style={{ backgroundColor: "#4c5659" }}
    >
      <Divider />
      <List component="div" disablePadding>
        {children
          .sort((a, b) => a.psl - b.psl)
          .map((item, index) => (
            <div style={{ marginTop: 5 }}>
              <ModuleChildrenItem
                {...item}
                key={index}
                activeRoute={activeRoute2}
                sidebarOpen={sidebarOpen}
                toggleMenu={toggleMenu2}
              />
            </div>
          ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItem: {
      "&.active": {
        background: "#1fc581",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
        "& .MuiListItemText-root": {
          color: "#FFFFFF",
        },
      },
     

      height: 40,
    },
    menuItemSelected: {
      "&.active": {
        background: "#3D8361",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
        "& .MuiListItemText-root": {
          color: "#FFFFFF",
        },
      },
      backgroundColor: "red",
      height: 40,
    },
    menuItemIcon: {
      color: "gold",
      marginLeft: -2,
    },
    ListItemText: {
      fontSize: 14,
      color: "white",
    },
    listItemTextOpen: {
      fontSize: 14,
      color: "white",
    },
    listItemTextClose: {
      fontSize: 12,
      color: "white",
    },
  })
);

export default ModuleItems;
