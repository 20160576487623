import {
  ADD_EVENT_CONTENTS,
  ADD_EVENT_CONTENTS_ERROR,
  ADD_EVENT_CONTENTS_OK,
  BEGIN_EDIT_EVENT_CONTENTS,
  BEGIN_EVENT_CONTENTS_DOWNLOAD,
  EVENT_CONTENTS_DELETED_ERROR,
  EVENT_CONTENTS_DELETED_OK,
  EVENT_CONTENTS_DOWNLOAD_ERROR,
  EVENT_CONTENTS_DOWNLOAD_OK,
  EVENT_CONTENTS_EDITED_ERROR,
  EVENT_CONTENTS_EDITED_OK,
  RETRIEVE_EVENT_CONTENTS_DELETE,
  RETRIEVE_EVENT_CONTENTS_EDIT,
} from "../../constants/modules/eventContentsConstant";

const initialState = {
  eventContents: [],
  error: false,
  loading: false,
  deleteEventContent: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_EVENT_CONTENTS_DOWNLOAD:
    case ADD_EVENT_CONTENTS:
    case BEGIN_EDIT_EVENT_CONTENTS:
      return {
        ...state,
        loading: action.payload,
        eventContent: action.eventContent,
      };

    case ADD_EVENT_CONTENTS_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_EVENT_CONTENTS_ERROR:
    case EVENT_CONTENTS_DOWNLOAD_ERROR:
    case EVENT_CONTENTS_DELETED_ERROR:
    case EVENT_CONTENTS_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EVENT_CONTENTS_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        eventContents: action.payload,
      };

    case RETRIEVE_EVENT_CONTENTS_DELETE:
      return {
        ...state,
        deleteEventContent: action.payload,
      };

    case EVENT_CONTENTS_DELETED_OK:
      return {
        ...state,
        eventContents: state.eventContents.data.filter((eventContent) => eventContent.id !== state.deleteEventContent),
        deleteEventContent: null,
      };

    case RETRIEVE_EVENT_CONTENTS_EDIT:
      return {
        ...state,
        editEventContent: action.payload,
      };

    case EVENT_CONTENTS_EDITED_OK:
      return {
        ...state,
        editEventContent: null,
        eventContents: state.eventContents.data.map((eventContent) =>
          eventContent.id === action.payload.id ? (eventContent = action.payload) : eventContent
        ),
      };

    default:
      return state;
  }
}
