import axiosClient from "../axiosClient";

// Events Api calls 

export async function bulkDeleteEventDB(ids) {
  return await axiosClient.post(`/events/bulk_delete`, ids);
}

export async function retrieveEventsData() {
  return await axiosClient.get("/events");
}

export async function addEventDB(value) {
  return await axiosClient.post("/events", value);
}

export async function deleteEventDB(id) {
  return await axiosClient.delete(`/events/${id}`);
}

export async function editEventDB(value) {
  console.log("editEvenst", value);
  return await axiosClient.put(`/events/${value.id}`, value);
}
