import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_NOTICE_CATEGORIES,
  BEGIN_EDIT_NOTICE_CATEGORIES,
  BEGIN_NOTICE_CATEGORIES_DOWNLOAD,
  RETRIEVE_NOTICE_CATEGORIES_DELETE,
} from "../../constants/modules/noticeCategoriesConstant";

import {
  addNoticeCategoryErrorAction,
  addNoticeCategoryOkAction,
  deleteNoticeCategoryErrorAction,
  deleteNoticeCategoryOkAction,
  downloadNoticeCategoriesErrorAction,
  downloadNoticeCategoriesOkAction,
  editNoticeCategoryErrorAction,
  editNoticeCategoryOkAction,
} from "../../actions/modules/noticeCategoriesActions";

import {
  addNoticeCategoryDB,
  deleteNoticeCategoryDB,
  editNoticeCategoryDB,
  retrieveNoticeCategoriesData,
} from "../../../config/apiCalls/noticeCategoriesAPICall.js";

// Retrieve products
// worker saga
export function* retrieveNoticeCategories() {
  try {
    const response = yield call(retrieveNoticeCategoriesData);
    
    if (response.status === 200) {
      yield put(downloadNoticeCategoriesOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadNoticeCategoriesErrorAction());
  }
}

// Create new product
// worker saga
export function* addNoticeCategory(action) {
  const noticeCategory = action.noticeCategory;

  try {
    const response = yield call(addNoticeCategoryDB, noticeCategory);
   
    console.log("response%%%%", response);

    if (response.status === 201) {
      yield delay(500);
      console.log("fire");
      yield put(addNoticeCategoryOkAction(noticeCategory));
      toast.success("Notice Category created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addNoticeCategoryErrorAction(true));
    toast.error("Notice Category created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteNoticeCategory(action) {
  const id = action.payload;
  try {
    yield call(deleteNoticeCategoryDB, id);
    yield deleteNoticeCategoryOkAction();
    toast.success("Notice Category deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteNoticeCategoryErrorAction();
  }
}

// Edit product
// worker saga
export function* editNoticeCategory(action) {
  const noticeCategory = action.noticeCategory;


  try {
    yield call(editNoticeCategoryDB, noticeCategory);
    yield editNoticeCategoryOkAction(noticeCategory);
    
    toast.success("Notice Category Updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    toast.success("Notice Category Updated Failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    yield editNoticeCategoryErrorAction();
  }
}

export function* watchNoticeCategoriesAsync() {
  yield takeLatest(BEGIN_NOTICE_CATEGORIES_DOWNLOAD, retrieveNoticeCategories);
  yield takeLatest(ADD_NOTICE_CATEGORIES, addNoticeCategory);
  yield takeLatest(RETRIEVE_NOTICE_CATEGORIES_DELETE, deleteNoticeCategory);
  yield takeLatest(BEGIN_EDIT_NOTICE_CATEGORIES, editNoticeCategory);
}
