import axiosClient from "../axiosClient";

// Event Categories Api calls 

export async function bulkDeleteEventCategoryDB(ids) {
  return await axiosClient.post(`/event_categories/bulk_delete`, ids);
}

export async function retrieveEventCategoriesData() {
  return await axiosClient.get("/event_categories");
}

export async function addEventCategoryDB(value) {
  return await axiosClient.post("/event_categories", value);
}

export async function deleteEventCategoryDB(id) {
  return await axiosClient.delete(`/event_categories/${id}`);
}

export async function editEventCategoryDB(value) {
  return await axiosClient.put(`/event_categories/${value.id}`, value);
}
