import {
  ADD_NOTICE_CATEGORIES,
  ADD_NOTICE_CATEGORIES_ERROR,
  ADD_NOTICE_CATEGORIES_OK,
  BEGIN_EDIT_NOTICE_CATEGORIES,
  BEGIN_NOTICE_CATEGORIES_DOWNLOAD,
  NOTICE_CATEGORIES_DELETED_ERROR,
  NOTICE_CATEGORIES_DELETED_OK,
  NOTICE_CATEGORIES_DOWNLOAD_ERROR,
  NOTICE_CATEGORIES_DOWNLOAD_OK,
  NOTICE_CATEGORIES_EDITED_ERROR,
  NOTICE_CATEGORIES_EDITED_OK,
  RETRIEVE_NOTICE_CATEGORIES_DELETE,
  RETRIEVE_NOTICE_CATEGORIES_EDIT,
} from "../../constants/modules/noticeCategoriesConstant";
  
  export const downloadNoticeCategoriesAction = () => ({
    type: BEGIN_NOTICE_CATEGORIES_DOWNLOAD,
    payload: true,
  });
  
  export const downloadNoticeCategoriesOkAction = (items) => ({
    type: NOTICE_CATEGORIES_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadNoticeCategoriesErrorAction = () => ({
    type: NOTICE_CATEGORIES_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addNoticeCategoryAction = (item) => ({
    type: ADD_NOTICE_CATEGORIES,
    payload: true,
    noticeCategory: item,
  });
  
  export const addNoticeCategoryOkAction = () => ({
    type: ADD_NOTICE_CATEGORIES_OK,
    payload: true,
  });
  
  export const addNoticeCategoryErrorAction = (state) => ({
    type: ADD_NOTICE_CATEGORIES_ERROR,
    payload: state,
  });
  
  export const deleteNoticeCategoryAction = (id) => ({
    type: RETRIEVE_NOTICE_CATEGORIES_DELETE,
    payload: id,
  });
  
  export const deleteNoticeCategoryOkAction = () => ({
    type: NOTICE_CATEGORIES_DELETED_OK,
  });
  
  export const deleteNoticeCategoryErrorAction = () => ({
    type: NOTICE_CATEGORIES_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveNoticeCategoryAction = (item) => ({
    type: RETRIEVE_NOTICE_CATEGORIES_EDIT,
    payload: item,
  });
  
  export const editNoticeCategoryAction = (item) => ({
    type: BEGIN_EDIT_NOTICE_CATEGORIES,
    noticeCategory: item,
  });
  
  export const editNoticeCategoryOkAction = (item) => ({
    type: NOTICE_CATEGORIES_EDITED_OK,
    payload: item,
  });
  
  export const editNoticeCategoryErrorAction = () => ({
    type: NOTICE_CATEGORIES_EDITED_ERROR,
    payload: true,
  });
  