import axios from "axios";

const axiosClient = axios.create({
  baseURL: "https://nb-api.clonestudiobd.com/api",
  // baseURL: "http://localhost:5000/api/",
  // baseURL: "https://dmdb.clonestudiobd.com/api",
  // baseURL: "http://103.30.190.241:5008/api/",
});

export default axiosClient;
