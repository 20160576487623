import {
  ADD_EVENT_CONTENTS,
  ADD_EVENT_CONTENTS_ERROR,
  ADD_EVENT_CONTENTS_OK,
  BEGIN_EDIT_EVENT_CONTENTS,
  BEGIN_EVENT_CONTENTS_DOWNLOAD,
  EVENT_CONTENTS_DELETED_ERROR,
  EVENT_CONTENTS_DELETED_OK,
  EVENT_CONTENTS_DOWNLOAD_ERROR,
  EVENT_CONTENTS_DOWNLOAD_OK,
  EVENT_CONTENTS_EDITED_ERROR,
  EVENT_CONTENTS_EDITED_OK,
  RETRIEVE_EVENT_CONTENTS_DELETE,
  RETRIEVE_EVENT_CONTENTS_EDIT,
} from "../../constants/modules/eventContentsConstant";
  
  export const downloadEventContentsAction = () => ({
    type: BEGIN_EVENT_CONTENTS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadEventContentsOkAction = (items) => ({
    type: EVENT_CONTENTS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadEventContentsErrorAction = () => ({
    type: EVENT_CONTENTS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addEventContentAction = (item) => ({
    type: ADD_EVENT_CONTENTS,
    payload: true,
    eventContent: item,
  });
  
  export const addEventContentOkAction = () => ({
    type: ADD_EVENT_CONTENTS_OK,
    payload: true,
  });
  
  export const addEventContentErrorAction = (state) => ({
    type: ADD_EVENT_CONTENTS_ERROR,
    payload: state,
  });
  
  export const deleteEventContentAction = (id) => ({
    type: RETRIEVE_EVENT_CONTENTS_DELETE,
    payload: id,
  });
  
  export const deleteEventContentOkAction = () => ({
    type: EVENT_CONTENTS_DELETED_OK,
  });
  
  export const deleteEventContentErrorAction = () => ({
    type: EVENT_CONTENTS_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveEventContentAction = (item) => ({
    type: RETRIEVE_EVENT_CONTENTS_EDIT,
    payload: item,
  });
  
  export const editEventContentAction = (item) => ({
    type: BEGIN_EDIT_EVENT_CONTENTS,
    eventContent: item,
  });
  
  export const editEventContentOkAction = (item) => ({
    type: EVENT_CONTENTS_EDITED_OK,
    payload: item,
  });
  
  export const editEventContentErrorAction = () => ({
    type: EVENT_CONTENTS_EDITED_ERROR,
    payload: true,
  });
  