import {
  ADD_NOTICES,
  ADD_NOTICES_ERROR,
  ADD_NOTICES_OK,
  BEGIN_EDIT_NOTICES,
  BEGIN_NOTICES_DOWNLOAD,
  NOTICES_DELETED_ERROR,
  NOTICES_DELETED_OK,
  NOTICES_DOWNLOAD_ERROR,
  NOTICES_DOWNLOAD_OK,
  NOTICES_EDITED_ERROR,
  NOTICES_EDITED_OK,
  RETRIEVE_NOTICES_DELETE,
  RETRIEVE_NOTICES_EDIT,
} from "../../constants/modules/noticesConstant";

const initialState = {
  notices: [],
  error: false,
  loading: false,
  deleteNotice: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_NOTICES_DOWNLOAD:
    case ADD_NOTICES:
    case BEGIN_EDIT_NOTICES:
      return {
        ...state,
        loading: action.payload,
        notice: action.notice,
      };

    case ADD_NOTICES_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_NOTICES_ERROR:
    case NOTICES_DOWNLOAD_ERROR:
    case NOTICES_DELETED_ERROR:
    case NOTICES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTICES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        notices: action.payload,
      };

    case RETRIEVE_NOTICES_DELETE:
      return {
        ...state,
        deleteNotice: action.payload,
      };

    case NOTICES_DELETED_OK:
      return {
        ...state,
        notices: state.notices.data.filter((notice) => notice.id !== state.deleteNotice),
        deleteNotice: null,
      };

    case RETRIEVE_NOTICES_EDIT:
      return {
        ...state,
        editNotice: action.payload,
      };

    case NOTICES_EDITED_OK:
      return {
        ...state,
        editNotice: null,
        notices: state.notices.data.map((notice) =>
          notice.id === action.payload.id ? (notice = action.payload) : notice
        ),
      };

    default:
      return state;
  }
}
