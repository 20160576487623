import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_NOTICE_AREAS,
  BEGIN_EDIT_NOTICE_AREAS,
  BEGIN_NOTICE_AREAS_DOWNLOAD,
  RETRIEVE_NOTICE_AREAS_DELETE,
} from "../../constants/modules/noticeAreasConstant";

import {
  addNoticeAreaErrorAction,
  addNoticeAreaOkAction,
  deleteNoticeAreaErrorAction,
  deleteNoticeAreaOkAction,
  downloadNoticeAreasErrorAction,
  downloadNoticeAreasOkAction,
  editNoticeAreaErrorAction,
  editNoticeAreaOkAction,
} from "../../actions/modules/noticeAreasActions";

import {
  addNoticeAreaDB,
  deleteNoticeAreaDB,
  editNoticeAreaDB,
  retrieveNoticeAreasData,
} from "../../../config/apiCalls/noticeAreasAPICall.js";



// Retrieve products
// worker saga
export function* retrieveNoticeAreas() {
  try {
    const response = yield call(retrieveNoticeAreasData);
    
    if (response.status === 200) {
      yield put(downloadNoticeAreasOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadNoticeAreasErrorAction());
  }
}

// Create new product
// worker saga
export function* addNoticeArea(action) {
  const noticeArea = action.noticeArea;

  console.log("event%%%%%", noticeArea);

  try {
    const response = yield call(addNoticeAreaDB, noticeArea);
    console.log("response222", response);

    if (response.status === 201) {
      yield delay(500);
      console.log("fire");
      yield put(addNoticeAreaOkAction(noticeArea));
      toast.success("Notice Area created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addNoticeAreaErrorAction(true));
    toast.error("Notice Area Created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteNoticeArea(action) {
  const id = action.payload;
  try {
    yield call(deleteNoticeAreaDB, id);
    yield deleteNoticeAreaOkAction();
    toast.success("Notice Area deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteNoticeAreaErrorAction();
  }
}

// Edit product
// worker saga
export function* editNoticeArea(action) {
  const noticeArea  = action.noticeArea;
  try {
    console.log("editNoticeAreaDB", "data",  noticeArea);

    yield call(editNoticeAreaDB, noticeArea);
    yield editNoticeAreaOkAction(noticeArea);

    console.log("editNoticeAreaDB", "data1",  noticeArea);
    toast.success("Notice Area Updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield editNoticeAreaErrorAction();
  }
}

export function* watchNoticeAreasAsync() {
  yield takeLatest(BEGIN_NOTICE_AREAS_DOWNLOAD, retrieveNoticeAreas);
  yield takeLatest(ADD_NOTICE_AREAS, addNoticeArea);
  yield takeLatest(RETRIEVE_NOTICE_AREAS_DELETE, deleteNoticeArea);
  yield takeLatest(BEGIN_EDIT_NOTICE_AREAS, editNoticeArea);
}
