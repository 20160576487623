export const ADD_EVENTS = "ADD_EVENTS";
export const ADD_EVENTS_OK = "ADD_EVENTS_OK";
export const ADD_EVENTS_ERROR = "ADD_EVENTS_ERROR";

export const BEGIN_EVENTS_DOWNLOAD = "BEGIN_EVENTS_DOWNLOAD";
export const EVENTS_DOWNLOAD_OK = "EVENTS_DOWNLOAD_OK";
export const EVENTS_DOWNLOAD_ERROR = "EVENTS_DOWNLOAD_ERROR";

export const RETRIEVE_EVENTS_DELETE = "RETRIEVE_EVENTS_DELETE";
export const EVENTS_DELETED_OK = "EVENTS_DELETED_OK";
export const EVENTS_DELETED_ERROR = "EVENTS_DELETED_ERROR";

export const RETRIEVE_EVENTS_EDIT = "RETRIEVE_EVENTS_EDITED";
export const BEGIN_EDIT_EVENTS = "BEGIN_EDIT_EVENTS";
export const EVENTS_EDITED_OK = "EVENTS_EDITED_OK";
export const EVENTS_EDITED_ERROR = "EVENTS_EDITED_ERROR";

export const RETRIEVE_EVENTS_BULK_DELETE = "RETRIEVE_EVENTS_BULK_DELETE";
export const EVENTS_BULK_DELETED_OK = "EVENTS_BULK_DELETED_OK";
export const EVENTS_BULK_DELETED_ERROR = "EVENTS_BULK_DELETED_ERROR";