import axiosClient from "../axiosClient";

// Notice Areas Api calls 

export async function bulkDeleteNoticeAreaDB(ids) {
  return await axiosClient.post(`/notice_areas/bulk_delete`, ids);
}

export async function retrieveNoticeAreasData() {
  return await axiosClient.get("/notice_areas");
}

export async function addNoticeAreaDB(value) {
  return await axiosClient.post("/notice_areas", value);
}

export async function deleteNoticeAreaDB(id) {
  return await axiosClient.delete(`/notice_areas/${id}`);
}

export async function editNoticeAreaDB(value) {
  return await axiosClient.put(`/notice_areas/${value.id}`, value);
}
