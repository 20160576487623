import {
  ADD_EVENTS,
  ADD_EVENTS_ERROR,
  ADD_EVENTS_OK,
  BEGIN_EDIT_EVENTS,
  BEGIN_EVENTS_DOWNLOAD,
  EVENTS_DELETED_ERROR,
  EVENTS_DELETED_OK,
  EVENTS_DOWNLOAD_ERROR,
  EVENTS_DOWNLOAD_OK,
  EVENTS_EDITED_ERROR,
  EVENTS_EDITED_OK,
  RETRIEVE_EVENTS_DELETE,
  RETRIEVE_EVENTS_EDIT,
} from "../../constants/modules/eventsConstant";
  
  export const downloadEventsAction = () => ({
    type: BEGIN_EVENTS_DOWNLOAD,
    payload: true,
  });
  
  export const downloadEventsOkAction = (items) => ({
    type: EVENTS_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadEventsErrorAction = () => ({
    type: EVENTS_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addEventAction = (item) => ({
    type: ADD_EVENTS,
    payload: true,
    event: item,
  });
  
  export const addEventOkAction = () => ({
    type: ADD_EVENTS_OK,
    payload: true,
  });
  
  export const addEventErrorAction = (state) => ({
    type: ADD_EVENTS_ERROR,
    payload: state,
  });
  
  export const deleteEventAction = (id) => ({
    type: RETRIEVE_EVENTS_DELETE,
    payload: id,
  });
  
  export const deleteEventOkAction = () => ({
    type: EVENTS_DELETED_OK,
  });
  
  export const deleteEventErrorAction = () => ({
    type: EVENTS_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveEventAction = (item) => ({
    type: RETRIEVE_EVENTS_EDIT,
    payload: item,
  });
  
  export const editEventAction = (item) => ({
    type: BEGIN_EDIT_EVENTS,
    event: item,
  });
  
  export const editEventOkAction = (item) => ({
    type: EVENTS_EDITED_OK,
    payload: item,
  });
  
  export const editEventErrorAction = () => ({
    type: EVENTS_EDITED_ERROR,
    payload: true,
  });
  