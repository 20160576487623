import {
  ADD_EVENT_CATEGORIES,
  ADD_EVENT_CATEGORIES_ERROR,
  ADD_EVENT_CATEGORIES_OK,
  BEGIN_EDIT_EVENT_CATEGORIES,
  BEGIN_EVENT_CATEGORIES_DOWNLOAD,
  EVENT_CATEGORIES_DELETED_ERROR,
  EVENT_CATEGORIES_DELETED_OK,
  EVENT_CATEGORIES_DOWNLOAD_ERROR,
  EVENT_CATEGORIES_DOWNLOAD_OK,
  EVENT_CATEGORIES_EDITED_ERROR,
  EVENT_CATEGORIES_EDITED_OK,
  RETRIEVE_EVENT_CATEGORIES_DELETE,
  RETRIEVE_EVENT_CATEGORIES_EDIT,
} from "../../constants/modules/eventCategoriesConstant";
  
  export const downloadEventCategoriesAction = () => ({
    type: BEGIN_EVENT_CATEGORIES_DOWNLOAD,
    payload: true,
  });
  
  export const downloadEventCategoriesOkAction = (items) => ({
    type: EVENT_CATEGORIES_DOWNLOAD_OK,
    payload: items,
  });
  
  export const downloadEventCategoriesErrorAction = () => ({
    type: EVENT_CATEGORIES_DOWNLOAD_ERROR,
    payload: true,
  });
  
  export const addEventCategoryAction = (item) => ({
    type: ADD_EVENT_CATEGORIES,
    payload: true,
    eventCategory: item,
  });
  
  export const addEventCategoryOkAction = () => ({
    type: ADD_EVENT_CATEGORIES_OK,
    payload: true,
  });
  
  export const addEventCategoryErrorAction = (state) => ({
    type: ADD_EVENT_CATEGORIES_ERROR,
    payload: state,
  });
  
  export const deleteEventCategoryAction = (id) => ({
    type: RETRIEVE_EVENT_CATEGORIES_DELETE,
    payload: id,
  });
  
  export const deleteEventCategoryOkAction = () => ({
    type: EVENT_CATEGORIES_DELETED_OK,
  });
  
  export const deleteEventCategoryErrorAction = () => ({
    type: EVENT_CATEGORIES_DELETED_ERROR,
    payload: true,
  });
  
  export const retrieveEventCategoryAction = (item) => ({
    type: RETRIEVE_EVENT_CATEGORIES_EDIT,
    payload: item,
  });
  
  export const editEventCategoryAction = (item) => ({
    type: BEGIN_EDIT_EVENT_CATEGORIES,
    eventCategory: item,
  });
  
  export const editEventCategoryOkAction = (item) => ({
    type: EVENT_CATEGORIES_EDITED_OK,
    payload: item,
  });
  
  export const editEventCategoryErrorAction = () => ({
    type: EVENT_CATEGORIES_EDITED_ERROR,
    payload: true,
  });
  