import {
  ADD_NOTICE_CATEGORIES,
  ADD_NOTICE_CATEGORIES_ERROR,
  ADD_NOTICE_CATEGORIES_OK,
  BEGIN_EDIT_NOTICE_CATEGORIES,
  BEGIN_NOTICE_CATEGORIES_DOWNLOAD,
  NOTICE_CATEGORIES_DELETED_ERROR,
  NOTICE_CATEGORIES_DELETED_OK,
  NOTICE_CATEGORIES_DOWNLOAD_ERROR,
  NOTICE_CATEGORIES_DOWNLOAD_OK,
  NOTICE_CATEGORIES_EDITED_ERROR,
  NOTICE_CATEGORIES_EDITED_OK,
  RETRIEVE_NOTICE_CATEGORIES_DELETE,
  RETRIEVE_NOTICE_CATEGORIES_EDIT,
} from "../../constants/modules/noticeCategoriesConstant";

const initialState = {
  noticeCategories: [],
  error: false,
  loading: false,
  deleteNoticeCategory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case BEGIN_NOTICE_CATEGORIES_DOWNLOAD:
    case ADD_NOTICE_CATEGORIES:
    case BEGIN_EDIT_NOTICE_CATEGORIES:
      return {
        ...state,
        loading: action.payload,
        noticeCategory: action.noticeCategory,
      };

    case ADD_NOTICE_CATEGORIES_OK:
      return {
        ...state,
        loading: false,
      };

    case ADD_NOTICE_CATEGORIES_ERROR:
    case NOTICE_CATEGORIES_DOWNLOAD_ERROR:
    case NOTICE_CATEGORIES_DELETED_ERROR:
    case NOTICE_CATEGORIES_EDITED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTICE_CATEGORIES_DOWNLOAD_OK:
      return {
        ...state,
        loading: false,
        error: null,
        noticeCategories: action.payload,
      };

    case RETRIEVE_NOTICE_CATEGORIES_DELETE:
      return {
        ...state,
        deleteNoticeCategory: action.payload,
      };

    case NOTICE_CATEGORIES_DELETED_OK:
      return {
        ...state,
        noticeCategories: state.noticeCategories.data.filter((noticeCategory) => noticeCategory.id !== state.deleteNoticeCategory),
        deleteNoticeCategory: null,
      };

    case RETRIEVE_NOTICE_CATEGORIES_EDIT:
      return {
        ...state,
        editNoticeCategory: action.payload,
      };

    case NOTICE_CATEGORIES_EDITED_OK:
      return {
        ...state,
        editNoticeCategory: null,
        noticeCategories: state.noticeCategories.data.map((noticeCategory) =>
          noticeCategory.id === action.payload.id ? (noticeCategory = action.payload) : noticeCategory
        ),
      };

    default:
      return state;
  }
}
