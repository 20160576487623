// Export all sagas
import { all } from "redux-saga/effects";
import { watchUsersAsync } from "../sagas/modules/usersSagas";
import { watchLoginAsync } from "./auth/authSagas";
import { watchPermissionAsync } from "./auth/permissionsSagas";
import { watchModulesAsync } from "./menuSagas";
import { watchEventCategoriesAsync } from "./modules/eventCategoriesSagas";
import { watchEventContentsAsync } from "./modules/eventContentsSagas";
import { watchEventsAsync } from "./modules/eventsSagas";
import { watchNoticeAreasAsync } from "./modules/noticeAreasSagas";
import { watchNoticeCategoriesAsync } from "./modules/noticeCategoriesSagas";
import { watchNoticesAsync } from "./modules/noticesSagas";





export default function* rootSaga() {
  yield all([

    watchNoticesAsync(),
    watchNoticeAreasAsync(),
    watchNoticeCategoriesAsync(),
    watchEventContentsAsync(),
    watchEventCategoriesAsync(),
    watchEventsAsync(),
    watchUsersAsync(),


    // previous module
    watchLoginAsync(),
    watchPermissionAsync(),
    // watchUsersAsync(),
    watchModulesAsync(),
    // watchBanksAsync(),
    // watchSuppliersAsync(),
    // watchBuyersAsync(),
    // watchOverseasOfficesAsync(),
    // watchColorsAsync(),
    // watchBuyerBrandsAsync(),
    // watchBuyerDepartmentsAsync(),
    // watchSizeSetsAsync(),
    // watchSeasonsAsync(),
    // watchProductTypesAsync(),
    // watchProductsAsync(),
    // watchBankAccountsAsync(),
    // watchBuyerTermsAsync(),
    // watchCompaniesAsync(),
    // watchCouriersAsync(),
    // watchExFactoriesAsync(),
    // watchFabricsAsync(),
    // watchPortsAsync(),
    // watchFabricTypesAsync(),
    // watchUserRolesAsync(),
    // watchUserAccessesAsync(),
    // watchFobTypesAsync(),
    // watchCurrenciesAsync(),
    // watchCountriesAsync(),
    // 
    // watchOrdersAsync(),
    // watchPaymentTermsAsync(),
    // watchTeamsAsync(),
    // watchOrderDetailsAsync(),
    // watchShipmentDetailsAsync(),
    // watchFactoryOrdersAsync(),
    // watchTnaActionsAsync(),
    // watchFactoryOrderDetailsAsync(),
    // watchFactoryShipmentDetailsAsync(),
    // watchTnaTemplateActionsAsync(),
    // watchTnaTemplatesAsync(),
    // watchTnaPlanHeaderAsync(),
    // watchTnaPlanDetailAsync(),
    // watchExFactoryInfosAsync(),
    // watchSalesContractActionsAsync(),
    // watchSalesContractsAsync(),
    // watchCommissionsAsync(),
    // watchExFactoryDetailsAsync(),
    // watchLCHeadersAsync(),
    // watchLCHeaderDetailsAsync(),
    // watchSalesContractAmendsAsync(),
    // watchCommissionDetailsAsync(),
    // watchApprovalPanelMembersAsync(),
    // watchApprovalPanelsAsync(),
    // watchDiscountDetailsAsync(),
    // watchDiscountsAsync(),
    // watchFactoryInvoicesAsync(),
    // watchRDLInvoicesAsync(),
    // watchTTAdvancesAsync(),
    // watchTTAdvanceDetailsAsync(),
    // watchApprovalFormSetupsAsync(),
    // watchCommissionInvoicesAsync(),
    // watchProceedRealizationsAsync(),
    // watchProceedRealizationDetailsAsync(),
    // watchFreightTermsAsync(),
    // watchUserLevelPrivilegesAsync(),
    // watchUserLevelsAsync(),
  ]);
}
