export const ADD_EVENT_CONTENTS = "ADD_EVENT_CONTENTS";
export const ADD_EVENT_CONTENTS_OK = "ADD_EVENT_CONTENTS_OK";
export const ADD_EVENT_CONTENTS_ERROR = "ADD_EVENT_CONTENTS_ERROR";

export const BEGIN_EVENT_CONTENTS_DOWNLOAD = "BEGIN_EVENT_CONTENTS_DOWNLOAD";
export const EVENT_CONTENTS_DOWNLOAD_OK = "EVENT_CONTENTS_DOWNLOAD_OK";
export const EVENT_CONTENTS_DOWNLOAD_ERROR = "EVENT_CONTENTS_DOWNLOAD_ERROR";

export const RETRIEVE_EVENT_CONTENTS_DELETE = "RETRIEVE_EVENT_CONTENTS_DELETE";
export const EVENT_CONTENTS_DELETED_OK = "EVENT_CONTENTS_DELETED_OK";
export const EVENT_CONTENTS_DELETED_ERROR = "EVENT_CONTENTS_DELETED_ERROR";

export const RETRIEVE_EVENT_CONTENTS_EDIT = "RETRIEVE_EVENT_CONTENTS_EDITED";
export const BEGIN_EDIT_EVENT_CONTENTS = "BEGIN_EDIT_EVENT_CONTENTS";
export const EVENT_CONTENTS_EDITED_OK = "EVENT_CONTENTS_EDITED_OK";
export const EVENT_CONTENTS_EDITED_ERROR = "EVENT_CONTENTS_EDITED_ERROR";

export const RETRIEVE_EVENT_CONTENTS_BULK_DELETE = "RETRIEVE_EVENT_CONTENTS_BULK_DELETE";
export const EVENT_CONTENTS_BULK_DELETED_OK = "EVENT_CONTENTS_BULK_DELETED_OK";
export const EVENT_CONTENTS_BULK_DELETED_ERROR = "EVENT_CONTENTS_BULK_DELETED_ERROR";