import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import "./index.css";
import {
  Signin
} from "./pages";
import store from "./redux/store";
import registerServiceWorker from "./registerServiceWorker";
import PrivateRoute from "./utils/router/PrivateRoute";

render(
  <Provider store={store}>
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/signin" component={Signin} />
          <PrivateRoute path="/app" component={Dashboard} />
          <Redirect exact from="/" to="/signin" />
          
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </AppProvider>
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
