import { toast } from "react-toastify";
import {
  call,
  delay,
  put,
  takeLatest
} from "redux-saga/effects";
import {
  ADD_EVENTS,
  BEGIN_EDIT_EVENTS,
  BEGIN_EVENTS_DOWNLOAD,
  RETRIEVE_EVENTS_DELETE,
} from "../../constants/modules/eventsConstant";

import {
  addEventErrorAction,
  addEventOkAction,
  deleteEventErrorAction,
  deleteEventOkAction,
  downloadEventsErrorAction,
  downloadEventsOkAction,
  editEventErrorAction,
  editEventOkAction,
} from "../../actions/modules/eventsActions";

import {
  addEventDB,
  deleteEventDB,
  editEventDB,
  retrieveEventsData,
} from "../../../config/apiCalls/eventsAPICall.js";

// Retrieve products
// worker saga
export function* retrieveEvents() {
  try {
    const response = yield call(retrieveEventsData);
    
    if (response.status === 200) {
      yield put(downloadEventsOkAction(response.data));
    }
  } catch (error) {
    yield put(downloadEventsErrorAction());
  }
}

// Create new product
// worker saga
export function* addEvent(action) {
  const event = action.event;

  console.log("event%%%%%", event);

  try {
    const response = yield call(addEventDB, event);
    console.log("response222", response);

    if (response.status === 201) {
      yield delay(500);
      console.log("fire");
      yield put(addEventOkAction(event));
      toast.success("Event created successfully!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  } catch (error) {
    yield put(addEventErrorAction(true));
    toast.error("Event created failed!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

// Delete product
// worker saga
export function* deleteEvent(action) {
  const id = action.payload;
  try {
    yield call(deleteEventDB, id);
    yield deleteEventOkAction();
    toast.success("Event deleted successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    yield deleteEventErrorAction();
  }
}

// Edit product
// worker saga
export function* editEvent(action) {
  const event = action.event;
  try {
    console.log("editEventDB", "data",  event);

    yield call(editEventDB, event);
    yield editEventOkAction(event);

    console.log("editEventDB", "data1",  event);
    toast.success("Event Updated successfully!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    console.log("editEventDB", "error",  error)
    yield editEventErrorAction();
  }
}

export function* watchEventsAsync() {
  yield takeLatest(BEGIN_EVENTS_DOWNLOAD, retrieveEvents);
  yield takeLatest(ADD_EVENTS, addEvent);
  yield takeLatest(RETRIEVE_EVENTS_DELETE, deleteEvent);
  yield takeLatest(BEGIN_EDIT_EVENTS, editEvent);
}
